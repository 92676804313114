import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const authEndpoint = SERVERCONFIG.url+'auth'

const authApiClient = {

  login: async(username, password, onComplete, onError) => {
    try {
      const response = await axios.post(
        authEndpoint+'/login',
        {username, password},
        { withCredentials: true }
      )
      onComplete(response.status, response.data)
    } catch (error) {
      onError(error)
    }
  },
  logout: async(onComplete, onError) => {
    try {
      const response = await axios.get(
        authEndpoint+'/logout',
        { withCredentials: true }
      )
      onComplete(response.status, response.data)
    } catch (error) {
      onError(error)
    }
  },
  getRole: async(onComplete, onError) => {
    try {
      const response = await axios.get(
        authEndpoint+'/role',
        { withCredentials: true }
      )
      onComplete(response.status, response.data)
    } catch (error) {
      onError(error)
    }
  }
}

export default authApiClient