import { Container, LinearProgress, List, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetAllCustomers } from '../../api/customersApiClient'
import { STYLES } from '../../themes/MainTheme'
import TransitionRight from '../shared/TransitionRight'
import CustomersListRow from './CustomersListRow'
import Searchbar from '../shared/Searchbar'

export default function CustomersList() {

  const [searchQuery, setSearchQuery] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const navigate = useNavigate()
  const { data: customers, isLoading, isError } = useGetAllCustomers(searchQuery, navigate)

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Kunden")
    }
  }, [isError])

  return (
    <Container maxWidth="md" disableGutters>
      <Searchbar
        class="searchbar-list"
        searchText={searchQuery}
        onChange={(value) => setSearchQuery(value)}
        onClear={() => setSearchQuery("")}
      />
      { isLoading && <LinearProgress color="inherit" /> }
      <List style={STYLES.listContainer}>
        {
          customers && customers.map((customer, i) => (
            <CustomersListRow customer={customer} key={i} />
          ))
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        TransitionComponent={TransitionRight}
      />
    </Container>
  )
}