import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetAllStorages } from "../../api/storagesApiClient"
import { Container, LinearProgress, List, Snackbar } from "@mui/material"
import TransitionRight from "../shared/TransitionRight"
import StoragesListRow from "./StoragesListRow"
import { STYLES } from "../../themes/MainTheme"
import Searchbar from "../shared/Searchbar"
import { useInView } from "react-intersection-observer"

export default function StoragesList() {

  const [searchQuery, setSearchQuery] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const navigate = useNavigate()
  const { data, isLoading, isError, fetchNextPage, isFetchingNextPage } = useGetAllStorages(searchQuery, navigate, true)
  const { ref, inView } = useInView()

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Lagerplätze")
    }
  }, [isError])

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  return (
    <Container maxWidth="md" disableGutters>
      <Searchbar
        class="searchbar-list"
        searchText={searchQuery}
        onChange={(value) => setSearchQuery(value)}
        onClear={() => setSearchQuery("")}
      />
      {(isLoading || isFetchingNextPage) && <LinearProgress color="inherit" />}
      <List style={STYLES.listContainer}>
        { data?.pages.map((page) => (
            page.data.map((storage) => (
              <StoragesListRow key={storage._id} storage={storage} />
            ))
          ))
        }
        { data?.pages.length > 0 &&
          <div ref={ref}></div>
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        TransitionComponent={TransitionRight}
      />
    </Container>
  )
}