import React, {useContext} from "react";
import PermissionContext from "./PermissionContext";

const Restricted = ({to, fallback, children}) => {

  const {isAllowedTo} = useContext(PermissionContext);

  if (isAllowedTo(to)) {
    return <>{children}</>
  }

  return <>{fallback}</>;
}

export default Restricted;