// Function to crop an image to a specified aspect ratio
export default function cropImage(inputFile, outputWidth, outputHeight) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.src = URL.createObjectURL(inputFile);

  return new Promise((resolve) => {
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      let newWidth, newHeight;

      if (outputWidth / outputHeight > aspectRatio) {
        newWidth = outputHeight * aspectRatio;
        newHeight = outputHeight;
      } else {
        newWidth = outputWidth;
        newHeight = outputWidth / aspectRatio;
      }

      // Set canvas dimensions
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      // Clear canvas and draw the image with black border if necessary
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, outputWidth, outputHeight);
      ctx.drawImage(img, (outputWidth - newWidth) / 2, (outputHeight - newHeight) / 2, newWidth, newHeight);

      // Convert the cropped canvas to a blob and resolve the promise
      canvas.toBlob((blob) => {
        resolve(blob);
      }, inputFile.type);
    };
  });
}