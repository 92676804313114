import React from "react";
import PackingListsList from "../packinglists/PackingListsList";
import BottomActionbar from "../shared/BottomActionbar";
import Restricted from "../shared/Restricted";

export default function PackingLists() {

  return (
    <div className="container">
      <Restricted to="packinglists.list">
        <PackingListsList />
        <BottomActionbar
          showAdd={false}
          addPermission="packinglists.create"
        />
      </Restricted>
    </div>
  );
}
