import React from "react";
import PermissionContext from "./PermissionContext";

const PermissionProvider = ({permissions, children}) => {

  const isAllowedTo = (permission) => {
    if (permission.includes(",")) {
      return permission.split(",").every((p) => permissions.includes(p))
    }

    return permissions.includes(permission)
  }

  return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>
}

export default PermissionProvider;