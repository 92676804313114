import React from "react"
import TransactionsList from "../transactions/TransactionsList"
import Restricted from "../shared/Restricted"

export default function Transactions() {

  return (
    <div className="container">
      <Restricted to="transactions.list">
        <TransactionsList />
      </Restricted>
    </div>
  )

}