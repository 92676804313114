import React from "react";
import BottomActionbar from "../shared/BottomActionbar";
import useGlobal from "../shared/GlobalContext";
import StoragesList from "../storages/StoragesList";
import Restricted from "../shared/Restricted";
import { useNavigate } from "react-router-dom";

export default function Storages() {

  const { toggleQrModal } = useGlobal()
  const navigate = useNavigate()

  return (
    <div className="container">
      <Restricted to="storages.list">
        <StoragesList />
        <BottomActionbar
          showAdd={true}
          onClickPlus={() => navigate("/storages/add")}
          onClickQr={() => toggleQrModal(true)}
          addPermission="storages.create"
        />
      </Restricted>
    </div>
  );
}