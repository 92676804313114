import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const customersEndpoint = SERVERCONFIG.url+'customers'
const api = axios.create({ baseURL: customersEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate('/login')
  } else {
    throw error
  }
}

const customersApiClient = {
  getAllCustomers: async(query, navigate) => {
    try {
      const {data} = await api.post('/', {query}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getCustomer: async(customerId, navigate) => {
    try {
      const {data} = await api.get('/'+customerId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addCustomer: async(formData, navigate) => {
    try {
      const {data} = await api.post('/add/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updateCustomer: async(customerId, formData, navigate) => {
    try {
      const {data} = await api.put('/'+customerId, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  deleteCustomer: async(customerId, navigate) => {
    try {
      const {data} = await api.delete('/'+customerId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getAssignedObjects: async(customerId, navigate) => {
    try {
      const {data} = await api.get('/assigned/'+customerId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getStorages: async(customerId, navigate) => {
    try {
      const {data} = await api.get('/storages/'+customerId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  
  }
}

export default customersApiClient

export const useGetAllCustomers = (query, navigate) => {
  return useQuery({
    queryFn: () => customersApiClient.getAllCustomers(query, navigate),
    queryKey: ['customers', {query}]
  })
}

export const useGetCustomer = (customerId, navigate, enabled) => {
  return useQuery({
    queryFn: () => customersApiClient.getCustomer(customerId, navigate),
    queryKey: ['customer', {customerId}],
    retry: false,
    gcTime: 0,
    enabled: enabled
  })
}

export const useAddCustomer = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({formData, navigate}) => customersApiClient.addCustomer(formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['customers']})
      onSuccess(res)
    }
  })
}

export const useUpdateCustomer = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({customerId, formData, navigate}) => customersApiClient.updateCustomer(customerId, formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['customers']})
      onSuccess(res)
    }
  })
}

export const useDeleteCustomer = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({customerId, navigate}) => customersApiClient.deleteCustomer(customerId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['customers']})
      onSuccess(res)
    }
  })
}

export const useGetAssignedObjects = (customerId, navigate, enabled) => {
  return useQuery({
    queryFn: () => customersApiClient.getAssignedObjects(customerId, navigate),
    queryKey: ['customerAssignedObjects', {customerId}],
    enabled: enabled
  })
}

export const useGetStorages = (customerId, navigate, enabled) => {
  return useQuery({
    queryFn: () => customersApiClient.getStorages(customerId, navigate),
    queryKey: ['customerStorages', {customerId}],
    enabled: enabled
  })
}