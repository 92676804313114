import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const storagesEndpoint = SERVERCONFIG.url+'storages'
const api = axios.create({ baseURL: storagesEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate('/login')
  } else {
    throw error
  }
}

const storagesApiClient = {
  getAllStorages: async(pageParam, query, navigate) => {
    try {
      const {data} = await api.post('/', {query, offset: pageParam}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getStorage: async(storageId, navigate) => {
    try {
      const {data} = await api.get('/'+storageId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addStorage: async(formData, navigate) => {
    try {
      const {data} = await api.post('/add/', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updateStorage: async(storageId, formData, navigate) => {
    try {
      const {data} = await api.put('/'+storageId, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  deleteStorage: async(storageId, navigate) => {
    try {
      const {data} = await api.delete('/'+storageId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getStoredObjects: async(storageId, navigate) => {
    try {
      const {data} = await api.get('/stored/'+storageId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  }
}

export default storagesApiClient

export const useGetAllStorages = (query, navigate, enabled) => {
  return useInfiniteQuery({
    queryFn: ({pageParam}) => storagesApiClient.getAllStorages(pageParam, query, navigate),
    queryKey: ['storages', {query}],
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.next,
    enabled: enabled
  })
}

export const useGetStorage = (storageId, navigate, enabled, onSuccess) => {
  return useQuery({
    queryFn: () => storagesApiClient.getStorage(storageId, navigate),
    queryKey: ['storage', {storageId}],
    gcTime: 0,
    retry: false,
    enabled: enabled
  })
}

export const useAddStorage = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({formData, navigate}) => storagesApiClient.addStorage(formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['storages']})
      onSuccess(res)
    }
  })
}

export const useUpdateStorage = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({storageId, formData, navigate}) => storagesApiClient.updateStorage(storageId, formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['storages']})
      onSuccess(res)
    }
  })
}

export const useDeleteStorage = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({storageId, navigate}) => storagesApiClient.deleteStorage(storageId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['storages']})
      onSuccess(res)
    }
  })
}

export const useGetStoredObjects = (storageId, navigate, enabled) => {
  return useQuery({
    queryFn: () => storagesApiClient.getStoredObjects(storageId, navigate),
    queryKey: ['storedObjects', {storageId}],
    enabled: enabled
  })
}