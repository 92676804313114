import { Container, Divider, Link, IconButton, List, ListItem, SwipeableDrawer, Toolbar, Button, ListItemButton } from '@mui/material'
import { Menu, MenuOpen } from "@mui/icons-material";
import React, { useState } from 'react'
import { SPACINGS } from '../themes/MainTheme'
import HideOnScroll from './shared/HideOnScroll';
import { Link as RouterLink } from 'react-router-dom';
import authApiClient from '../api/authApiClient';

const allNavigationLinks = [
  { name: "Objekte", href: "/" },
  { name: "Lagerplätze", href: "/storages" },
  { name: "Kunden", href: "/customers" },
  { name: "Projekte", href: "/projects" },
  { name: "Ladelisten", href: "/packinglists" },
  { name: "Transaktionen", href: "/transactions" }
]

export default function Header({role}) {

  // Naive role filtering
  const navigationLinks = role === "admin" ? allNavigationLinks : allNavigationLinks.filter(item => item.name !== "Transaktionen")
  
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleLogout = () => {
    authApiClient.logout((status, res) => {
      if (status === 200) {
        window.location.href = '/login';
      } else {
        console.log("Error while logging out")
        window.location.href = '/login';
      }
    }, (err) => {
      console.log("Error while logging out")
      window.location.href = '/login';
    })
  }

  return (
    <HideOnScroll>
      <Container maxWidth="md" disableGutters>
        <Toolbar disableGutters>
          <Container disableGutters sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {navigationLinks.map((item) => (
              <Link
                component={RouterLink}
                underline="none"
                to={item.href}
                key={item.name}
                style={styles.link}
              >
                {item.name}
              </Link>
            ))}
            <Button type="button" variant="contained" style={{backgroundColor: 'black'}} onClick={handleLogout}>
              Logout
            </Button>
          </Container>
          <IconButton sx={{ display: { xs: 'flex', md: 'none' } }} onClick={() => setIsMenuOpen(true)}>
            <Menu style={{paddingLeft: SPACINGS.xs}} fontSize="large"/>
          </IconButton>
        </Toolbar>
      </Container>
      <SwipeableDrawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onOpen={() => setIsMenuOpen(true)}
      >
        <div>
          <IconButton onClick={() => setIsMenuOpen(false)}>
            <MenuOpen style={{paddingLeft: SPACINGS.xs}} fontSize="large"/>
          </IconButton>
        </div>
        <Divider />
        <List sx={{justifyContent: "center"}}>
          {navigationLinks.map((item) => (
            <ListItem key={item.name} onClick={() => setIsMenuOpen(false)}>
              <Link component={RouterLink} underline="none" to={item.href} style={styles.link}>
                {item.name}
              </Link>
            </ListItem>
          ))}
          <ListItemButton onClick={handleLogout}>
            <Button type="button" style={{...styles.link, ...styles.logout}}>
              Logout
            </Button>
          </ListItemButton>
        </List>
      </SwipeableDrawer>
    </HideOnScroll>
  )
}

const styles = {
  link: {
    marginLeft: SPACINGS.s,
    marginRight: SPACINGS.s
  },
  logout: {
    backgroundColor: "black",
    color: "white",
    paddingLeft: SPACINGS.m,
    paddingRight: SPACINGS.m
  }
}