import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import SERVERCONFIG from "../utils/serverConfig"
import axios from 'axios'

const projectsEndpoint = SERVERCONFIG.url+'projects'
const api = axios.create({ baseURL: projectsEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate('/login')
  } else {
    throw error
  }
}

const projectsApiClient = {
  getAllProjects: async(pageParam, query, navigate) => {
    try {
      const {data} = await api.post('/', {query, offset: pageParam}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getProject: async(projectId, navigate) => {
    try {
      const {data} = await api.get('/'+projectId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  addProject: async(formData, navigate) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      if (formData.image) {
        requestData.append('image', formData.image, 'cropped.jpg')
      }

      const {data} = await api.post('/add/', requestData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  updateProject: async(projectId, formData, navigate) => {
    try {
      const requestData = new FormData()
      requestData.append('name', formData.name)
      requestData.append('description', formData.description)
      if (formData.image) {
        requestData.append('image', formData.image, 'cropped.jpg')
      }

      const {data} = await api.put('/'+projectId, requestData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  deleteProject: async(projectId, navigate) => {
    try {
      const {data} = await api.delete('/'+projectId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getAssignedObjects: async(projectId, navigate) => {
    try {
      const {data} = await api.get('/assigned/'+projectId)
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  }
}

export default projectsApiClient

export const useGetAllProjects = (query, navigate, enabled) => {
  return useInfiniteQuery({
    queryFn: ({pageParam}) => projectsApiClient.getAllProjects(pageParam, query, navigate),
    queryKey: ['projects', {query}],
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.next,
    enabled: enabled
  })
}

export const useGetProject = (projectId, navigate, enabled) => {
  return useQuery({
    queryFn: () => projectsApiClient.getProject(projectId, navigate),
    queryKey: ['project', {projectId}],
    gcTime: 0,
    retry: false,
    enabled: enabled
  })
}

export const useAddProject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({formData, navigate}) => projectsApiClient.addProject(formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['projects']})
      onSuccess(res)
    }
  })
}

export const useUpdateProject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({projectId, formData, navigate}) => projectsApiClient.updateProject(projectId, formData, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['projects']})
      onSuccess(res)
    }
  })
}

export const useDeleteProject = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({projectId, navigate}) => projectsApiClient.deleteProject(projectId, navigate),
    onSuccess: (res) => {
      queryClient.invalidateQueries({queryKey: ['projects']})
      onSuccess(res)
    }
  })
}

export const useGetAssignedObjects = (projectId, navigate, enabled) => {
  return useQuery({
    queryFn: () => projectsApiClient.getAssignedObjects(projectId, navigate),
    queryKey: ['projectAssignedObjects', {projectId}],
    enabled: enabled
  })
}