import React, { useRef, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import { useScrollTrigger } from '@mui/material';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger({ threshold: 10 });
  const appBarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentAppBarHeight = appBarRef.current.offsetHeight;
      const searchbars = document.getElementsByClassName('searchbar-list');

      for (let i = 0; i < searchbars.length; i++) {
        const searchbar = searchbars[i];
        searchbar.style.top = `${currentAppBarHeight}px`;

        if (trigger) {
          searchbar.style.transform = `translateY(-${currentAppBarHeight}px)`;
        } else {
          searchbar.style.transform = 'translateY(0)';
        }        
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    resizeObserver.observe(appBarRef.current);

    window.addEventListener('scroll', handleScroll);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [appBarRef, trigger]);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar position="sticky" color="default" ref={appBarRef}>
        {children}
      </AppBar>
    </Slide>
  );
}

export default HideOnScroll;
