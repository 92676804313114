import React, { useEffect, useState } from "react";
import { Button, Container, Grid, LinearProgress, Paper, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TransitionRight from "../shared/TransitionRight";
import { useNavigate } from "react-router";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import QrReader from 'react-qr-scanner'
import CONSTS from "../../utils/consts";
import { useAssignObject, useStoreObject } from "../../api/objectsApiClient";
import useGlobal from "../shared/GlobalContext";
import { useGetStorage } from "../../api/storagesApiClient";
import { useGetProject } from "../../api/projectsApiClient";
import StorageSlotsDialog from "../shared/StorageSlotsDialog";

export default function QrScannerMenu() {

  const [isLoading, setIsLoading] = useState(false)
  const [scanned, setScanned] = useState(false)
  const [scannedStorageId, setScannedStorageId] = useState("")
  const [scannedProjectId, setScannedProjectId] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const navigate = useNavigate()
  const {
    showQrModal,
    toggleQrModal,
    setInstancesToStore,
    instancesToStore
  } = useGlobal()

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => {
        setSnackbarVisible(false)
        setIsLoading(false)
        setScanned(false)
      }, 1000)
    }
  }

  const storageQuery = useGetStorage(
    scannedStorageId,
    navigate,
    instancesToStore == null && scannedStorageId !== ""
  )

  const projectQuery = useGetProject(
    scannedProjectId,
    navigate,
    instancesToStore == null && scannedProjectId !== ""
  )

  const storeMutation = useStoreObject((res) => {
    setIsLoading(false)
    navigate(`/${res._id}`)
    toggleQrModal(false)
    setInstancesToStore(null)
  })

  const assignMutation = useAssignObject((res) => {
    setIsLoading(false)
    navigate(`/${res._id}`)
    toggleQrModal(false)
    setInstancesToStore(null)
  })

  const storeObject = (isStorage, assignToId, storageSlots) => {
    try {
      if (isStorage) {
        storeMutation.mutate({
          objectId: instancesToStore.objectId,
          storageData: {
            storage: assignToId,
            storageSlots: storageSlots,
            instancesToStore: instancesToStore.instances
          },
          navigate
        })
      } else {
        assignMutation.mutate({
          objectId: instancesToStore.objectId,
          projectData: {
            project: assignToId,
            instancesToAssign: instancesToStore.instances
          },
          navigate
        })
      }
    } catch (error) {
      toggleSnackbar(true, "Es ist ein Fehler aufgetreten.")
    }
  }

  const handleQrCodeScanned = (data) => {
    if (data && !scanned) {
      setIsLoading(true)
      setScanned(true)
      try {
        const jsonObject = JSON.parse(data.text)

        if (jsonObject.typeId === CONSTS.storageTypeId) {
          if (instancesToStore == null) {
            // Get and open storage
            toggleQrModal(false)
            navigate(`/storages/${jsonObject.id}`)
          } else {
            setIsLoading(false)
            setScannedStorageId(jsonObject.id)
          }
        } else if (jsonObject.typeId === CONSTS.projectTypeId) {
          if (instancesToStore == null) {
            // Get and open project
            toggleQrModal(false)
            navigate(`/projects/${jsonObject.id}`)
          } else {
            setIsLoading(false)
            setScannedProjectId(jsonObject.id)
            handleProjectScan(jsonObject.id)
          }
        } else {
          toggleSnackbar(true, "Ungültiger QR-Code")
        }
      } catch (error) {
        toggleSnackbar(true, "Es ist ein Fehler aufgetreten.")
      }
    }
  }

  const handleQrCodeError = (error) => {
    toggleSnackbar(true, "Beim Scannen ist ein Fehler aufgetreten.")
  }

  const confirmStorageSlots = (storageSlots) => {
    storeObject(true, scannedStorageId, storageSlots)
  }

  const handleProjectScan = (projectId) => {
    storeObject(false, projectId)
  }

  const onClose = () => {
    toggleQrModal(false)
    setInstancesToStore(null)
  }

  const resetScan = () => {
    setScannedProjectId("")
    setScannedStorageId("")
  }

  // Error handling
  useEffect(() => {
    if (storeMutation.isError) {
      toggleSnackbar(true, "Fehler beim lagern des Objektes")
      resetScan()
    } else if (assignMutation.isError) {
      toggleSnackbar(true, "Fehler beim zuordnen des Objektes")
      resetScan()
    } else if (storageQuery.isError) {
      toggleSnackbar(true, "Lagerplatz konnte nicht geladen werden")
      resetScan()
    } else if (projectQuery.isError) {
      toggleSnackbar(true, "Projekt konnte nicht geladen werden")
      resetScan()
    }
  }, [storeMutation.isError, storageQuery.isError, assignMutation.isError, projectQuery.isError])

  // Loading
  useEffect(() => {
    if (storageQuery.isLoading || projectQuery.isLoading || storeMutation.isPending || assignMutation.isPending) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [storageQuery.isLoading, projectQuery.isLoading, storeMutation.isPending, assignMutation.isPending])

  // Show storage
  useEffect(() => {
    if (showQrModal && instancesToStore == null && storageQuery.data) {
      setIsLoading(false)
      // setSelectedStorage(storageQuery.data)
      // toggleStorageModal(true)
      toggleQrModal(false)
    }
  }, [showQrModal, instancesToStore, storageQuery.data, toggleQrModal])

  // Show project
  useEffect(() => {
    if (showQrModal &&  instancesToStore == null && projectQuery.data) {
      setIsLoading(false)
      // setSelectedProject(projectQuery.data)
      // toggleProjectModal(true)
      toggleQrModal(false)
    }
  }, [showQrModal, instancesToStore, projectQuery.data, toggleQrModal])

  return (
    <>
      <Paper square elevation={3}>
        <Grid container style={{height: SPACINGS.xl}}>
          <Grid item xs={10} style={{paddingLeft: SPACINGS.s}}>
          </Grid>
          <Grid item xs={2} style={{textAlign: "center"}}>
            <Button sx={{...STYLES.button, ...styles.closeButton }} onClick={onClose}>
              <CloseIcon fontSize="large" />
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Container style={styles.container}>
        <LinearProgress style={{visibility: isLoading ? "visible" : "hidden", marginBottom: SPACINGS.m}} />
        <Container>
          <Paper style={styles.scannerContainer} square elevation={1}>
            <QrReader
              onScan={handleQrCodeScanned}
              onError={handleQrCodeError}
              constraints={{
                video: {
                  facingMode: "environment",
                  aspectRatio: 1,
                  zoom: true
                }
              }}
              style={{ width: "100%" }}
            />
          </Paper>
          <StorageSlotsDialog
            isOpen={scannedStorageId && instancesToStore != null}
            onClose={onClose}
            onConfirm={confirmStorageSlots}  
          />
          <Snackbar
            open={snackbarVisible}
            message={snackbarMessage}
            TransitionComponent={TransitionRight}
          />
        </Container>
      </Container>      
    </>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "92%",
    padding: 0
  },
  scannerContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    aspectRatio: '1/1'
  },
  checkboxContainer: {
    width: "100%",
    marginTop: SPACINGS.s
  },
  closeButton: {
    width: SPACINGS.xl
  }
}