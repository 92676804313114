import React from "react";
import { Container, Modal, Paper, Slide } from "@mui/material";
import { SPACINGS } from "../themes/MainTheme";

export default function MenuModal(props) {
  return (
    <Modal open={props.isOpen} style={styles.modal}>
      <Slide direction="up" in={props.isOpen} mountOnEnter unmountOnExit>
        <Container maxWidth="md" disableGutters style={styles.container}>
          <Paper style={styles.paper} elevation={3} square>
            {props.children}
          </Paper>
        </Container>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  paper: {
    height: "100%",
    width: "100%"
  },
  container: {
    height: "98%",
    width: "100%",
    margin: SPACINGS.s
  }
}