import { Avatar, Grid, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import { SPACINGS, STYLES } from "../../themes/MainTheme"
import SERVERCONFIG from "../../utils/serverConfig"
import { useNavigate } from "react-router-dom"
import ImageModal from "../ImageModal"
import { useState } from "react"

export default function ProjectsListRow(props) {
  const project = props.project

  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(project._id)
    } else {
      navigate(`/projects/${project._id}`)
    }
  }

  const handleImageClick = () => {
    if (project.thumbnail !== null) {
      setIsImageModalOpen(true)
    }
  }

  return (
    <Grid container>
      <Grid item xs={2} sm={1}>
        <ListItemButton
          disabled={project.thumbnail === null}
          style={styles.imageListButton}
          key={project._id}
          divider
          onClick={handleImageClick}
        >
          { project.thumbnail !== null &&
            <>
              <ListItemAvatar>
                <Avatar variant="square" sx={{width: 64, height: 64}}>
                  <img
                    src={SERVERCONFIG.url + project.thumbnail}
                    width={64}
                    height={64}
                    alt="object.name"
                  />
                </Avatar>
              </ListItemAvatar>
              <ImageModal
                isOpen={isImageModalOpen}
                onClose={() => setIsImageModalOpen(false)}
                imageUrl={SERVERCONFIG.url + project.image}
              />
            </>
          }
        </ListItemButton>
      </Grid>
      <Grid item xs={10} sm={11}>
        <ListItemButton
          style={{height: SPACINGS.xl}}
          key={project._id}
          divider
          onClick={handleClick}
        >
          <ListItemText
            sx={{ maxWidth: 'calc(100% - 16px)' }}
            primary={project.name}
            primaryTypographyProps={{ style: STYLES.textTruncate }}
            secondary={project.description}
            secondaryTypographyProps={{ style: STYLES.textTruncate }}
          />
          <ListItemSecondaryAction>
            {
              props.secondary
            }
          </ListItemSecondaryAction>
        </ListItemButton>
      </Grid>
    </Grid>
  )
}

const styles = {
  imageListButton: {
    height: SPACINGS.xl,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  }
}