import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import authApiClient from "../../api/authApiClient";
import { Button, Container, Stack } from "@mui/material";
import TransitionRight from "../shared/TransitionRight";

export default function Login() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setsnackbarMessage] = useState("")

  const handleLogin = async() => {
    if (!username) {
      toggleSnackbar(true, "Benutzername darf nicht leer sein.")
      return
    } else if (!password) {
      toggleSnackbar(true, "Passwort darf nicht leer sein.")
      return
    }

    authApiClient.login(username, password, (status, res) => {
      if (status === 200) {
        window.location.href = '/';
      } else {
        toggleSnackbar(true, "Fehler beim Login, bitte versuche es erneut.")
      }
    }, (err) => {
      if (err.response && err.response.status === 401) {
        toggleSnackbar(true, "Benutzername oder Passwort ungültig.")
      } else {
        toggleSnackbar(true, "Fehler beim Login, bitte versuche es erneut.")
      }
    })
  }

  const toggleSnackbar = (visible, message = "") => {
    setSnackbarVisible(true)
    setsnackbarMessage(message)

    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  return (
    <div>
      <h1 style={styles.title}>Login</h1>
      <Container maxWidth="md" disableGutters>
        <Stack spacing={2}>
          <TextField
            label="Benutzername"
            variant="standard"
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Passwort"
            type="password"
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="button" variant="contained" style={{backgroundColor: 'black'}} onClick={handleLogin}>
            Einloggen
          </Button>
          <Snackbar
            open={snackbarVisible}
            message={snackbarMessage}
            TransitionComponent={TransitionRight}
          />
        </Stack>
      </Container>
    </div>
  );
}

const styles = {
  title: {
    textAlign: 'center'
  }
}