import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Add, QrCodeScanner } from "@mui/icons-material";
import React from 'react'
import Restricted from './Restricted';

export default function BottomActionbar({showAdd, onClickPlus, onClickQr, addPermission}) {
    return (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
        >
        <Restricted to={addPermission}>
          { showAdd &&
            <BottomNavigationAction icon={<Add fontSize="large" />} onClick={onClickPlus} />
          }
        </Restricted>
        <Restricted to="storages.list,projects.list">
          <BottomNavigationAction icon={<QrCodeScanner fontSize="large" />} onClick={onClickQr} />
        </Restricted>
        </BottomNavigation>
      </Paper>
    );
  }