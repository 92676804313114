import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SPACINGS } from '../themes/MainTheme';

export default function StorageCheckboxes(props) {

  const handleCheckboxChange = (label) => (event) => {
    if (event.target.checked) {
      // If the checkbox is checked, add the label to the array and sort it
      props.setSelectedStorages([...props.selectedStorages, label].sort());
    } else {
      // If the checkbox is unchecked, remove the label from the array
      props.setSelectedStorages(props.selectedStorages.filter((item) => item !== label));
    }
  };

  return (
    <FormGroup style={styles.storageForm}>
      <FormControlLabel
        control={<Checkbox
          checked={props.selectedStorages.includes('A')}
          onChange={handleCheckboxChange('A')}
        />}
        label="A"
      />
      <FormControlLabel
        control={<Checkbox
          checked={props.selectedStorages.includes('B')}
          onChange={handleCheckboxChange('B')}
        />}
        label="B"
      />
      <FormControlLabel
        control={<Checkbox
          checked={props.selectedStorages.includes('C')}
          onChange={handleCheckboxChange('C')}
        />}
        label="C"
      />
    </FormGroup>
  );
}

const styles = {
  storageForm: {
    flexDirection: "row",
    margin: SPACINGS.s
  }
}