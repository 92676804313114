import React from "react";
import { Container, Modal, Zoom } from "@mui/material";

export default function ImageModal({ isOpen, onClose, imageUrl }) {

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  }

  return (
    <Modal open={isOpen} style={styles.modal} onClose={handleClose}>
      <Zoom in={isOpen} mountOnEnter unmountOnExit>
        <Container style={styles.container} maxWidth="md" disableGutters onClick={handleClose}>
          <img src={imageUrl} alt="object" style={{width: "100%", maxWidth: 500 }} onClick={(e) => e.stopPropagation()} />
        </Container>
      </Zoom>
    </Modal>
  );
}

const styles = {
  modal: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}