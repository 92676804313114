import axios from 'axios'
import SERVERCONFIG from '../utils/serverConfig'
import { useQuery } from '@tanstack/react-query'

const transactionsEndpoint = SERVERCONFIG.url+'transactions'
const api = axios.create({ baseURL: transactionsEndpoint, withCredentials: SERVERCONFIG.withCredentials })

const handleError = (navigate, error) => {
  if (error.response && error.response.status === 401) {
    navigate('/login')
  } else {
    throw error
  }
}

const transactionsApiClient = {
  getAllTransactions: async(navigate) => {
    try {
      const {data} = await api.get('/')
      return data
    } catch (error) {
      handleError(navigate, error)
    }
  },
  getTransaction: async(transactionId, navigate) => {
    try {
      const {data} = await api.get('/'+transactionId)
      return data
    } catch (error) {
      handleError(error)
    }
  }
}

export default transactionsApiClient

export const useGetAllTransactions = (navigate) => {
  return useQuery({
    queryFn: () => transactionsApiClient.getAllTransactions(navigate),
    queryKey: ['transactions']
  })
}

export const useGetTransaction = (transactionId, navigate, enabled) => {
  return useQuery({
    queryFn: () => transactionsApiClient.getTransaction(transactionId, navigate),
    queryKey: ['transaction', {transactionId}],
    retry: false,
    gcTime: 0,
    enabled: enabled
  })
}