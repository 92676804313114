import React from "react";
import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SPACINGS, STYLES } from "../../themes/MainTheme";

export default function BackButton(props) {
  return (
    <Button sx={STYLES.buttonV2} onClick={props.onBack}>
      <ArrowBackIosNewIcon fontSize="medium" style={{marginRight: SPACINGS.s}} />
      <Typography fontSize={16}>Zurück</Typography>
    </Button>
  );
}