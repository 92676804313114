import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function DeleteDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Löschen Bestätigen
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button onClick={props.handleDelete}>Löschen</Button>
      </DialogActions>
    </Dialog>
  );
}