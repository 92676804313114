export const initialState = {
  showQrModal: false,
  showListAssignModal: false,
  instancesToStore: null,
  assignTypeId: -1,
}

const globalReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case "TOGGLE_QR_MODAL":
      return {
        ...state,
        showQrModal: payload.showQrModal
      }
    case "TOGGLE_LIST_ASSIGN_MODAL":
      return {
        ...state,
        showListAssignModal: payload.showListAssignModal
      }
    case "SET_INSTANCES_TO_STORE":
      return {
        ...state,
        instancesToStore: payload.instancesToStore
      }
    case "SET_ASSIGN_TYPE_ID":
      return {
        ...state,
        assignTypeId: payload.assignTypeId
      }
    default:
      throw new Error(`No case for type ${type} found in globalReducer`)
  }
}

export default globalReducer