import { Typography } from '@mui/material'
import React from 'react'
import UnassignedGroup from './UnassignedGroup'
import AssignedGroup from './AssignedGroup'

export default function ObjectInstanceList({object, customers, refetchObject}) {

  return (
    <>
      { object.unassignedInstances && object.unassignedInstances.length > 0 &&
        <>
          <Typography>Nicht zugewiesen:</Typography>
          {
            object.unassignedInstances.map((group, i) => (
              <UnassignedGroup
                group={group}
                customers={customers}
                objectId={object._id}
                refetchObject={refetchObject}
                key={i}
              />
            ))
          }
        </>
      }
      { object.instances && object.instances.length > 0 &&
        <>
          <Typography>Zugewiesen:</Typography>
          {
            object.instances.map((group, i) => (
              <AssignedGroup
                group={group}
                customers={customers}
                objectId={object._id}
                refetchObject={refetchObject}
                key={i}
              />
            ))
          }
        </>
      }
    </>
  )
}