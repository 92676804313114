import { IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import React from "react";
import { SPACINGS } from "../../themes/MainTheme";

export default function Searchbar(props) {
  return (
    <Paper style={styles.paperContainer} className={props.class} square>
      <form style={styles.formContainer}>
        <TextField
          id="search-bar"
          className="text"
          value={props.searchText}
          onInput={(e) => props.onChange(e.target.value)}
          variant="standard"
          placeholder="Suchen..."
          style={{width: "100%"}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                { props.searchText ?
                  ( 
                    <IconButton type="button" aria-label="clear" onClick={props.onClear}>
                      <ClearIcon />
                    </IconButton>
                  ) :
                  (
                    <IconButton type="button" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  )
                }
              </InputAdornment>
            )
          }}
        />
      </form>
    </Paper>
  );
}

const styles = {
  formContainer: {
    width: "100%",
    margin: SPACINGS.s
  },
  paperContainer: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    transition: 'transform 0.15s ease-in-out'
  }
}