import React, { useState } from 'react'
import { SPACINGS } from '../../themes/MainTheme'
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { FileUploadOutlined, Add, Remove, PostAdd } from "@mui/icons-material";
import AmountDialog from '../shared/AmountDialog';
import { useAddInstances, useRemoveInstances, useUnstoreObject, useUpdateCustomer } from '../../api/objectsApiClient';
import { useNavigate } from 'react-router-dom';
import Restricted from '../shared/Restricted';
import useGlobal from '../shared/GlobalContext';
import CONSTS from '../../utils/consts';

export default function AssignedGroup({group, customers, objectId, refetchObject}) {

  const [showAssignAmountDialog, setShowAssignAmountDialog] = useState(false)
  const [showUnassignAmountDialog, setShowUnassignAmountDialog] = useState(false)
  const [showAddAmountDialog, setShowAddAmountDialog] = useState(false)
  const [showRemoveAmountDialog, setShowRemoveAmountDialog] = useState(false)
  const unstoreMutation = useUnstoreObject(() => {refetchObject()})
  const updateCustomerMutation = useUpdateCustomer(() => {refetchObject()})
  const addInstancesMutation = useAddInstances(() => {refetchObject()})
  const removeInstancesMutation = useRemoveInstances(() => {refetchObject()})

  const navigate = useNavigate()
  const { setInstancesToStore, toggleListAssignModal, setAssignTypeId } = useGlobal()

  const changeCustomer = (customer) => {
    updateCustomerMutation.mutate({
      objectId,
      customerData: {
        instancesToUpdate: group,
        customer
      },
      navigate
    })
  }

  const unassignObject = (amount) => {
    unstoreMutation.mutate({objectId, instanceData: {instances: group.slice(0,amount)}, navigate})
  }

  const addObjects = (amount) => {
    addInstancesMutation.mutate({
      objectId, 
      instanceData: {
        instanceToCopy: group[0],
        amount
      },
      navigate
    })
  }

  const removeObjects = (amount) => {
    removeInstancesMutation.mutate({
      objectId,
      instanceData: {
        instancesToDelete: group.slice(0,amount)
      },
      navigate
    })
  }

  const handlePackingListClick = () => {
    setShowAssignAmountDialog(true)
    setAssignTypeId(CONSTS.packingListTypeId)
  }

  const assignObject = (amount) => {
    let instances = group.slice(0,amount)

    toggleListAssignModal(true)
    setInstancesToStore({
      objectId,
      instances
    })
  }

  return (
    <Paper style={styles.container} square elevation={1}>
      <Box style={styles.numberBox}>
        <Restricted to="objects.addInstances">
          <Button onClick={() => setShowAddAmountDialog(true)}>
            <Add fontSize='large' />
          </Button>
        </Restricted>
        <Typography fontSize={28}>
          {group.length}
        </Typography>
        <Restricted to="objects.removeInstances">
          <Button onClick={() => setShowRemoveAmountDialog(true)}>
            <Remove fontSize='large' />
          </Button>
        </Restricted>
      </Box>
      <Box style={styles.formContainer}>
        <Stack spacing={2} style={styles.stack}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Restricted
              to="objects.changeCustomer"
              fallback={
                <TextField
                  inputProps={{readOnly: true}}
                  variant="standard"
                  label="Kunde"
                  value={group[0].customer == null ? "" : group[0].customer.name}
                />
              }
            >
              <InputLabel id="customer-select">Kunde</InputLabel>
              <Select
                labelId="customer-select"
                value={group[0].customer == null ? "" : group[0].customer._id}
                onChange={(e) => changeCustomer(e.target.value)}
                label="Kunde"
              >
                <MenuItem value={""}>Ohne Kunde</MenuItem>
                {
                  customers && customers.map((customer, i) => (
                    <MenuItem value={customer._id} key={i}>{customer.name}</MenuItem>
                  ))
                }
              </Select>
            </Restricted>
          </FormControl>
          { group[0].storage &&
            <TextField
              label="Lagerplatz"
              inputProps={{readOnly: true}}
              variant="standard"
              value={`${group[0].storage.name} ${group[0].storageSlots.join(", ")}`}
            />
          }
          { group[0].project &&
            <TextField
              label="Projekt"
              inputProps={{readOnly: true}}
              variant="standard"
              value={group[0].project.name}
            />
          }
          <Restricted to="objects.unstore,objects.pack">
            <Container disableGutters style={styles.unassignContainer}>
              <Button type="button" variant="contained" style={styles.instanceActionButton} onClick={() => setShowUnassignAmountDialog(true)}>
                <><FileUploadOutlined style={{marginRight: SPACINGS.s}} />Entnehmen</>
              </Button>
              <Button type="button" variant="contained" style={styles.instanceActionButton} onClick={handlePackingListClick}>
                <><PostAdd style={{marginRight: SPACINGS.s}} />Laden</>
              </Button>
            </Container>
          </Restricted>
          <AmountDialog
            isOpen={showAssignAmountDialog}
            onClose={() => setShowAssignAmountDialog(false)}
            onConfirm={assignObject}
            message={"Wie viele Objekte sollen zur Ladeliste hinzugefügt werden?"}
            maxAmount={group.length}
            allAmount={group.length}
          />
          <AmountDialog
            isOpen={showUnassignAmountDialog}
            onClose={() => setShowUnassignAmountDialog(false)}
            onConfirm={unassignObject}
            message={"Wie viele Objekte möchtest Du entnehmen?"}
            maxAmount={group.length}
            allAmount={group.length}
          />
          <AmountDialog
            isOpen={showAddAmountDialog}
            onClose={() => setShowAddAmountDialog(false)}
            onConfirm={addObjects}
            message={"Wie viele Objekte möchtest Du hinzufügen?"}
            maxAmount={999}
          />
          <AmountDialog
            isOpen={showRemoveAmountDialog}
            onClose={() => setShowRemoveAmountDialog(false)}
            onConfirm={removeObjects}
            message={"Wie viele Objekte möchtest Du entfernen?"}
            maxAmount={group.length}
            allAmount={group.length}
          />
        </Stack>
      </Box>
    </Paper>
  )
}

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  numberBox: {
    width: "10%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column"
  },
  formContainer: {
    flex: 1,
    display: "flex"
  },
  stack: {
    width: "100%",
    padding: SPACINGS.s
  },
  instanceActionButton: {
    width: "49%",
    backgroundColor: "black"
  },
  unassignContainer: {
    justifyContent: "space-between",
    display: "flex"
  }
}