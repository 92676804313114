import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import StorageCheckboxes from "../StorageCheckboxes";

export default function StorageSlotsDialog(props) {

  const [selectedStorages, setSelectedStorages] = useState([])

  const handleConfirm = () => {
    props.onConfirm(selectedStorages)
    props.onClose()
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Fächer auswählen
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
        <StorageCheckboxes selectedStorages={selectedStorages} setSelectedStorages={setSelectedStorages} />
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button onClick={handleConfirm}>Bestätigen</Button>
      </DialogActions>
    </Dialog>
  );
}