import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField } from "@mui/material";
import { SPACINGS } from "../../themes/MainTheme";

export default function AmountDialog(props) {

  const [amount, setAmount] = React.useState(1)
  const [snackbarVisible, setSnackbarVisible] = useState(false)

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const handleConfirm = () => {
    if (amount < 1 || amount > props.maxAmount) {
      toggleSnackbar()
    } else {
      props.onConfirm(amount)
      handleClose()
    }
  }

  const handleClose = () => {
    setAmount(1)
    props.onClose()
  }

  const toggleSnackbar = () => {
    setSnackbarVisible(true)
    setTimeout(() => setSnackbarVisible(false), 3000)
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Anzahl Bestätigen
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
        <Grid container>
          <Grid item xs={9}>
            <TextField
              type="number"
              variant="standard"
              value={amount}
              onChange={handleAmountChange}
            />
          </Grid>
          <Grid item xs={3}>
            { props.allAmount &&
              <Button onClick={() => setAmount(props.allAmount)}>Alle</Button>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button onClick={handleConfirm}>Bestätigen</Button>
      </DialogActions>
      <Snackbar
        style={{bottom: SPACINGS.m}}
        open={snackbarVisible}
        message={`Die Anzahl darf nur zwischen 1 und ${props.maxAmount} liegen.`}
      />
    </Dialog>
  );
}