export const PALETTE = {
    black: 'black',
    darkGrey: '#989898',
    white: 'white',
    lightGrey: '#E5E5E5'
};

export const COLORS = {
  primaryText: PALETTE.black,
  secondaryText: PALETTE.darkGrey,
  primaryBackground: PALETTE.white,
  secondaryBackground: PALETTE.lightGrey
}

export const SPACINGS = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  l: 32,
  ll: 48,
  xl: 64
}

// TODO: adapt
export const TEXTVARIANTS = {
  button: {
    fontSize: 24,
    color: COLORS.primaryText
  },
  titleSmall: {
    fontSize: 20,
    color: COLORS.primaryText
  },
  bodyPrimary: {
    fontSize: 16,
    color: COLORS.primaryText
  },
  bodySecondary: {
    fontSize: 16,
    color: COLORS.secondaryText
  }
}

export const STYLES = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    height: SPACINGS.xl
  },
  buttonV2: {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    height: SPACINGS.ll
  },
  listContainer: {
    paddingBottom: 56
  },
  textTruncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  detailHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    transition: 'transform 0.15s ease-in-out',
    marginBottom: SPACINGS.xs
  },
}
