const ROLES = {
  admin: [
    "objects.list",
    "objects.create",
    "objects.update",
    "objects.delete",
    "objects.unstore",
    "objects.store",
    "objects.assign",
    "objects.pack",
    "objects.changeCustomer",
    "objects.addInstances",
    "objects.removeInstances",

    "storages.list",
    "storages.create",
    "storages.update",
    "storages.delete",

    "projects.list",
    "projects.create",
    "projects.update",
    "projects.delete",

    "customers.list",
    "customers.create",
    "customers.update",
    "customers.delete",

    "transactions.list",
  
    "packinglists.list",
    "packinglists.create",
    "packinglists.update",
    "packinglists.delete"
  ],
  user: [
    "objects.list",
    "objects.create",
    "objects.update",
    "objects.store",
    "objects.unstore",
    "objects.assign",
    "objects.pack",
    "objects.changeCustomer",
    "objects.addInstances",
    "objects.removeInstances",

    "storages.list",

    "projects.list",

    "customers.list",

    "packinglists.list",
    "packinglists.create"
  ],
  none: []
}

export default ROLES