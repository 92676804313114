import React from "react";
import useGlobal from "../shared/GlobalContext";
import ProjectsList from "../projects/ProjectsList";
import BottomActionbar from "../shared/BottomActionbar";
import Restricted from "../shared/Restricted";
import { useNavigate } from "react-router-dom";

export default function Customers() {

  const { toggleQrModal } = useGlobal()
  const navigate = useNavigate()

  return (
    <div className="container">
      <Restricted to="projects.list">
        <ProjectsList />
        <BottomActionbar
          showAdd={true}
          onClickPlus={() => navigate("/projects/add")}
          onClickQr={() => toggleQrModal(true)}
          addPermission="projects.create"
        />
      </Restricted>
    </div>
  );
}