import React from "react"
import useGlobal from "./components/shared/GlobalContext";

import MenuModal from "./components/MenuModal";
import QrScannerMenu from "./components/objects/QrScannerMenu";
import ListAssign from "./components/objects/ListAssign";

export default function Modals() {

  const {
    showQrModal,
    showListAssignModal
  } = useGlobal()

  return (
    <>
      <MenuModal isOpen={showQrModal}>
        <QrScannerMenu />
      </MenuModal>
      <MenuModal isOpen={showListAssignModal}>
        <ListAssign />
      </MenuModal>
    </>
  )
}