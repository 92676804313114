import { Container, Divider, Grid, InputLabel, LinearProgress, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SPACINGS, PALETTE, STYLES } from "../../themes/MainTheme";
import BackButton from "../shared/BackButton";
import { useGetTransaction } from "../../api/transactionsApiClient";
import moment from "moment";
import TransitionRight from "../shared/TransitionRight";

export default function TransactionDetail() {
  const params = useParams();
  const hasTransaction = params.id != null;

  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const navigate = useNavigate()

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { data: transaction, isLoading, isError } = useGetTransaction(params.id, navigate, hasTransaction);

  // Error handling
  useEffect(() => {
    if (isError) {
      toggleSnackbar(true, "Fehler beim laden der Transaktion")
    }
  }, [isError])

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1} style={STYLES.detailHeader}>
        <Grid container stlye={{height: SPACINGS.ll}}>
          <Grid item xs={8}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid>
          <Grid item xs={4} style={styles.rightAlignedGrid}>
          </Grid>
        </Grid>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer}>
        <Paper style={styles.formContainer} square elevation={1}>
          <Stack spacing={2} style={styles.formStack}>
            { transaction &&
              <>
                <TextField
                  inputProps={{readOnly: true}}
                  variant="standard"
                  label="Beschreibung"
                  value={transaction.name}
                />
                <TextField
                  inputProps={{readOnly: true}}
                  variant="standard"
                  label="Zeitpunkt"
                  value={moment(transaction.date).format("DD.MM.YYYY HH:mm:ss")}
                />
                <TextField
                  inputProps={{readOnly: true}}
                  variant="standard"
                  label="Nutzer"
                  value={transaction.user ? transaction.user.username: ""}
                />
                <InputLabel>
                  <Typography variant="caption">Parameter</Typography>
                </InputLabel>
                <Typography component="pre">
                    {JSON.stringify(JSON.parse(transaction.body), null, 2)}
                </Typography>
                <Divider style={{backgroundColor: PALETTE.darkGrey}} />
                { transaction.transactionElement &&
                  (
                    <>
                      <InputLabel>
                        <Typography variant="caption">Aktualisiertes Objekt</Typography>
                      </InputLabel>
                      <Typography component="pre">
                          {JSON.stringify(transaction.transactionElement, null, 2)}
                      </Typography>
                      <Divider style={{backgroundColor: PALETTE.darkGrey}} />
                    </>
                  )
                }
              </>
            }
          </Stack>
        </Paper>
      </Container>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        TransitionComponent={TransitionRight}
      />
    </Container>
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  }
}