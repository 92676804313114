import React from "react";
import CustomersList from "../customers/CustomersList";
import BottomActionbar from "../shared/BottomActionbar";
import useGlobal from "../shared/GlobalContext";
import Restricted from "../shared/Restricted";
import { useNavigate } from "react-router-dom";

export default function Customers() {

  const { toggleQrModal } = useGlobal()
  const navigate = useNavigate()

  return (
    <div className="container">
      <Restricted to="customers.list">
        <CustomersList />
        <BottomActionbar
          showAdd={true}
          onClickPlus={() => navigate("/customers/add")}
          onClickQr={() => toggleQrModal(true)}
          addPermission="customers.create"
        />
      </Restricted>
    </div>
  );
}