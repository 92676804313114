import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export default function ImageUploadButton(props) {
  const [isAndroid, setIsAndroid] = useState(false);
  const [imageUploadAnchor, setImageUploadAnchor] = useState(null)
  const isMenuopen = Boolean(imageUploadAnchor)

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    setIsAndroid(/android/i.test(userAgent))
  }, [setIsAndroid])

  // These functions are only used on Android

  const handleImageUpload = (event) => {
    setImageUploadAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setImageUploadAnchor(null)
  }

  return (
    <>
      <input
        accept="image/*"
        id="input-image-upload"
        type="file"
        onChange={(e) => props.handleCapture(e.target)}
        style={{ display: "none" }}
      />
      { isAndroid ?
        <>
          <input
            accept="image/*"
            id="input-image-capture"
            capture="environment"
            type="file"
            onChange={(e) => props.handleCapture(e.target)}
            style={{ display: "none" }}
          />
          <Button style={styles.uploadButton} onClick={handleImageUpload}>
            <CameraAltIcon fontSize="large" />
          </Button>
          <Menu
            anchorEl={imageUploadAnchor}
            open={isMenuopen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => setImageUploadAnchor(null)}>
              <label htmlFor="input-image-capture" style={styles.uploadLabel}>
                Kamera
              </label>
            </MenuItem>
            <MenuItem onClick={() => setImageUploadAnchor(null)}>
              <label htmlFor="input-image-upload" style={styles.uploadLabel}>
                Gallerie
              </label>
            </MenuItem>
          </Menu>
        </> :
        <Button style={styles.uploadButton}>
          <label htmlFor="input-image-upload" style={styles.uploadLabel}>
            <CameraAltIcon fontSize="large" />
          </label>
        </Button>
      }
    </>
  );
}

const styles = {
  uploadButton: {
    padding: 0,
    flex: 1,
    width: "100%"
  },
  uploadLabel: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}