import React from "react"
import { Route, Routes } from "react-router-dom";

import Storages from "./components/pages/Storages"
import Login from './components/pages/Login'
import Objects from "./components/pages/Objects";
import Customers from "./components/pages/Customers";
import Projects from "./components/pages/Projects";
import Modals from "./Modals";
import Transactions from "./components/pages/Transactions";
import PackingListDetail from "./components/packinglists/PackingListDetail";
import PackingLists from "./components/pages/PackingLists";
import ObjectDetail from "./components/objects/ObjectDetail";
import ProjectDetail from "./components/projects/ProjectDetail";
import StorageDetail from "./components/storages/StorageDetail";
import TransactionDetail from "./components/transactions/TransactionDetail";
import CustomerDetail from "./components/customers/CustomerDetail";

export default function Routing({role}) {

  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login/>} />

        <Route exact path="/" element={<Objects/>} />
        <Route exact path="/:id" element={<ObjectDetail/>} />

        <Route exact path="/storages" element={<Storages/>} />
        <Route exact path="/storages/:id" element={<StorageDetail/>} />

        <Route exact path="/customers" element={<Customers/>} />
        <Route exact path="/customers/:id" element={<CustomerDetail/>} />

        <Route exact path="/projects" element={<Projects/>} />
        <Route exact path="/projects/:id" element={<ProjectDetail/>} />

        <Route exact path="/packinglists" element={<PackingLists/>} />
        <Route exact path="/packinglists/:id" element={<PackingListDetail/>} />
        { role === "admin" &&
          <>
            <Route exact path="/transactions" element={<Transactions/>} />
            <Route exact path="/transactions/:id" element={<TransactionDetail/>} />
          </>
        }
      </Routes>
      <Modals />
    </>
  )
}