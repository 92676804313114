import { createContext, useContext, useReducer } from 'react'
import globalReducer, { initialState } from './globalReducer'

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState)

  const toggleQrModal = (showQrModal) => {
    dispatch({
      type: "TOGGLE_QR_MODAL",
      payload: {
        showQrModal
      }
    })
  }

  const toggleListAssignModal = (showListAssignModal) => {
    dispatch({
      type: "TOGGLE_LIST_ASSIGN_MODAL",
      payload: {
        showListAssignModal
      }
    })
  }

  const setInstancesToStore = (instancesToStore) => {
    dispatch({
      type: "SET_INSTANCES_TO_STORE",
      payload: {
        instancesToStore
      }
    })
  }

  const setAssignTypeId = (assignTypeId) => {
    dispatch({
      type: "SET_ASSIGN_TYPE_ID",
      payload: {
        assignTypeId
      }
    })
  }

  const value = {
    showQrModal: state.showQrModal,
    showListAssignModal: state.showListAssignModal,
    instancesToStore: state.instancesToStore,
    assignTypeId: state.assignTypeId,
    toggleQrModal,
    toggleListAssignModal,
    setInstancesToStore,
    setAssignTypeId
  }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

const useGlobal = () => {
  const context = useContext(GlobalContext)

  if (context === undefined) {
    throw new Error("useGlobal must be used within GlobalContext")
  }

  return context
}

export default useGlobal