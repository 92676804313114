import { ListItemButton, ListItemText } from "@mui/material"
import { STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router-dom"

export default function CustomersListRow(props) {
  const customer = props.customer
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(customer)
    } else {
      navigate(`/customers/${customer._id}`)
    }
  }

  return (
    <ListItemButton
      key={customer._id}
      divider
      onClick={handleClick}
    >
      <ListItemText
        primary={customer.name}
        primaryTypographyProps={{ style: STYLES.textTruncate }}
        secondary={customer.note}
        secondaryTypographyProps={{ style: STYLES.textTruncate }}
      />
    </ListItemButton>
  )
}