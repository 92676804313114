import { Avatar, Checkbox, Grid, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import React, {useEffect} from 'react'
import SERVERCONFIG from "../../utils/serverConfig"
import { COLORS, STYLES } from "../../themes/MainTheme"
import { useNavigate } from "react-router-dom"
import { useAssignObject, useRestoreObjectStorage } from "../../api/objectsApiClient"

export default function PackingListEntry(props) {
  const project = props.project
  const entry = props.entry
  const object = entry.object
  const storageName = object.instances[0].storage != null ? `${object.instances[0].storage.name} ${object.instances[0].storageSlots.join(",")}` : ""
  const isPacked = object.instances.every(instance => instance.project != null);
  const amount = object.instances.length

  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(object)
    } else {
      // Open object
      navigate(`/${object._id}`)
    }
  }

  const assignMutation = useAssignObject(() => props.showSnackbar(true, "Objekte zugewiesen"))
  const restoreStorageMutation = useRestoreObjectStorage(() => props.showSnackbar(true, "Objekte zurückgestellt"))

  const handleCheckboxChange = () => {
    if (!isPacked) {
      // Assign object if not packed
      assignMutation.mutate({
        objectId: object._id,
        projectData: {
          project: project,
          instancesToAssign: object.instances
        },
        navigate
      })
    } else {
      // Restore last storage
      restoreStorageMutation.mutate({
        objectId: object._id,
        instanceData: {
          instancesToRestore: object.instances
        },
        navigate
      })
    }
  }

  useEffect(() => {
    if (assignMutation.isError) {
      props.showSnackbar(true, "Fehler beim Zuweisen der Objekte")
    }
    if (restoreStorageMutation.isError) {
      props.showSnackbar(true, "Fehler beim Zurückstellen der Objekte")
    }
  }, [assignMutation.isError, restoreStorageMutation.isError, props])

  return (
    <Grid container>
      <Grid item xs={11}>
        <ListItemButton
          key={object._id}
          divider
          onClick={handleClick}
          style={{display:'flex', justifyContent:'space-between'}}
          sx={{padding:0}}
        >
          <ListItemAvatar>
            { object.thumbnail != null &&
              (
                <Avatar variant="square" sx={{width: 48, height: 48}}>
                  <img src={SERVERCONFIG.url + object.thumbnail} width={48} height={48} alt="object.name" />
                </Avatar>
              )
            }
            {
              object.thumbnail == null &&
              (
                <div style={{backgroundColor: COLORS.primaryBackground}}></div>
              )
            }
          </ListItemAvatar>
          <ListItemText
            style={{width: '88%'}}
            primary={object.name}
            primaryTypographyProps={{ style: STYLES.textTruncate }}
            secondary={storageName}
            secondaryTypographyProps={{ style: STYLES.textTruncate }}
          />
          <ListItemText
            style={{ width: '12%', textAlign: 'center'}}
            primary={amount}
            primaryTypographyProps={{ style: STYLES.textTruncate }}
          />
        </ListItemButton>
      </Grid>
      <Grid item xs={1} style={{justifyContent: 'center', display: 'flex'}}>
        <Checkbox
          checked={isPacked}
          onChange={handleCheckboxChange}
          style={{padding: 0}}
        />
      </Grid>
    </Grid>
  )
}
