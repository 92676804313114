import { Button, Container, Grid, LinearProgress, List, Paper, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"

import { useGetPackingList, useUpdatePackingList, useDeletePackingList } from "../../api/packingListsApiClient";
import TransitionRight from "../shared/TransitionRight";
import { SPACINGS, STYLES } from "../../themes/MainTheme";
import { Delete, Save } from "@mui/icons-material";
import Restricted from "../shared/Restricted";
import DeleteDialog from "../shared/DeleteDialog";
import BackButton from "../shared/BackButton";
import PackingListEntry from "./PackingListEntry";

export default function PackingListDetail() {

  const params = useParams();

  const [formData, setFormData] = useState({
    name: '',
    note: ''
  })
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const onClose = () => {
    navigate('/packinglists')
  }

  const toggleSnackbar = (visible, message="") => {
    setSnackbarVisible(visible)
    setSnackbarMessage(message)
  
    if (visible) {
      setTimeout(() => setSnackbarVisible(false), 3000)
    }
  }

  const { data: packingList, isLoading: isLoadingPackingList, isError: isErrorPackingList } = useGetPackingList(params.id, navigate)
  const updateMutation = useUpdatePackingList(onClose)
  const deleteMutation = useDeletePackingList(onClose)

  const checkInput = () => {
    if (!formData.name) {
      toggleSnackbar(true, "Der Name darf nicht leer sein")
      return false
    }
    return true
  }

  const updateForm = async() => {
    if (checkInput()) {
      updateMutation.mutate({packingListId: params.id, formData, navigate})
    }
  }

  useEffect(() => {
    if (packingList) {
      setFormData({
        name: packingList.name,
        note: packingList.note
      })
    }
  }, [packingList])

  useEffect(() => {
    if (updateMutation.isError) {
      toggleSnackbar(true, "Fehler beim speichern der Ladeliste")
    } else if (deleteMutation.isError) {
      toggleSnackbar(true, "Fehler beim löschen der Ladeliste")
    } else if (isErrorPackingList) {
      toggleSnackbar(true, "Fehler beim laden der Ladeliste")
    }
  }, [updateMutation.isError, deleteMutation.isError, isErrorPackingList])

  const isLoading = updateMutation.isPending || deleteMutation.isPending || isLoadingPackingList

  return (
    <Container maxWidth="md" disableGutters>
      <Paper square elevation={1}>
        <Grid container style={{height: SPACINGS.ll}}>
          <Grid item xs={8}>
            <Container style={{height: "100%"}}>
              <BackButton onBack={() => navigate(-1)} />
            </Container>
          </Grid>
          <Grid item xs={4} style={styles.rightAlignedGrid}>
            { packingList &&
              <>
                <Restricted to="packinglists.update">
                  <Button style={{...STYLES.buttonV2, ...styles.topActionButton }} onClick={updateForm}>
                    <Save fontSize="medium" />
                  </Button>
                </Restricted>
                <Restricted to="packinglists.delete">
                  <Button style={{...STYLES.buttonV2, ...styles.topActionButton }} onClick={() => setShowDeleteDialog(true)}>
                    <Delete fontSize="medium" />
                  </Button>
                  <DeleteDialog
                    isOpen={showDeleteDialog}
                    onClose={() => setShowDeleteDialog(false)}
                    message={"Möchtest Du diese Ladeliste wirklich löschen?"}
                    handleDelete={() => deleteMutation.mutate({packingListId: params.id, navigate})}
                  />
                </Restricted>
              </>
            }
          </Grid>
        </Grid>
      </Paper>
      { isLoading && <LinearProgress color="inherit" /> }
      <Container style={styles.mainContainer}>
          <Paper style={styles.formContainer} square elevation={1}>
            <Stack spacing={2} style={styles.formStack}>
              <TextField
                label="Name"
                variant="standard"
                inputProps={{readOnly: true}}
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
              />
              <TextField
                label="Notiz"
                variant="standard"
                value={formData.note}
                onChange={(e) => setFormData({...formData, note: e.target.value})}
              />
            </Stack>
          </Paper>
      </Container>
      <List style={STYLES.listContainer}>
        {
          packingList && packingList.entries && packingList.entries.map((entry, i) => (
            <PackingListEntry
              project={packingList.project}
              entry={entry}
              showSnackbar={toggleSnackbar}
              key={i}
            />
          ))
        }
      </List>
      <Snackbar
        open={snackbarVisible}
        message={snackbarMessage}
        TransitionComponent={TransitionRight}
      />
    </Container>
  )
}

const styles = {
  mainContainer: {
    width: "100%",
    height: "92%",
    padding: 0,
    maxHeight: "88%",
    overflowY: "auto"
  },
  formContainer: {
    flex: 1,
    display: "flex",
    marginTop: SPACINGS.s,
    marginBottom: SPACINGS.s
  },
  listContainer: {
    width: "100%",
    padding: SPACINGS.s
  },
  formStack: {
    width: "100%",
    padding: SPACINGS.s
  },
  topActionButton: {
    width: SPACINGS.xl
  },
  rightAlignedGrid: {
    justifyContent: "right",
    flexDirection: "row",
    display: "flex"
  }
}